.section {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page {
  padding: 0px 20px;

}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  border-radius: 5px;
  max-width: 100vw;
  z-index: 0;
  background: url("/public/store_page_background.jpg");
  transition: all .5s;
}

.play {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.play button {
  background-color: goldenrod;
  border-color: none;
  border-radius: 5px;
  padding: 10px 50px;
  color: #000522;
  &:hover {
    transition: all .5s;
    background-color: gold;
    scale: 1.15;
    cursor: pointer;
  }
}

.blog-page {
    display: flex;
    flex-flow: column;
    gap: 20px;
    padding: 0px 20vw;
}

.blog-title {
  text-transform: uppercase;
  font-weight: bold;
}

.blog {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-around;
}

.blog h2 {
  margin: 10px 0px 0px 0px;
  align-self: start;
}

.blog-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #060c29;
  border-radius: 5px;
  width: 50vw;
  height: 150px;
  z-index: 5;
  border-color:#060c29;
  border-style: ridge;
  &:hover {
    border-color: white;
    scale: 1.05;
    transition: all .5s; 
    cursor: pointer;
  }
}

.blog-item p {
  overflow: hidden;
  text-overflow: ellipsis;
}


.App {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-weight: 700;
  font-size: xx-large;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.image {
  width: 450px;
}

@media (min-width: 768px) {
  .mobile {
    width: 250px;
  }
  
  .blog {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .mobile {
    width: 250px;
  }
  
  .blog {
    flex-direction: column;
  }
  
  .blog-item {
    padding: 10px;
    width: 85vw;
  }

}

@media (max-width: 320px) {
  .mobile {
    width: 125px;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#snackbar {
  z-index: 100;
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 5px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  z-index: 100;
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
