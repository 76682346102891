nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-style: italic;
  margin: 20px;
}

.nav-title {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.nav-buttons {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-right: 10px;
  gap: 20px;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
}


.reddit {
  color: #ff4500;
}

.reddit:hover {
  color: #eb6330;
}

.discord {
  color: #5865f2;
}
.discord:hover {
  color: #404eed;
}

.twitter {
  color: #1da1f2;
}
.twitter:hover {
  color: #1573ad;
}

.tiktok {
  color: #25f4ee;
}
.tiktok:hover {
  color: #19afaa;
}

.youtube {
  color: #ff0000;
}
.youtube:hover {
  color: #af0101;
}
